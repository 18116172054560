.about_container{
    display: grid;
    grid-template-columns: 35% 50%;
    grid-gap: 5%;
   margin-left: 2rem;
}

.about_me{
    grid-column: 1;
    width: 100%;
    height: max-content;
    border-radius: 2rem;
    background: linear-gradient(45deg,transparent,var(--color-primary),
    transparent);
    display: grid;
    align-items: center;

}

.about_me-img{
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition)
}

.about_me-img:hover{
     transform: rotate(0)
}

.about_cards{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 1.5rem;
}
.about_card{
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition)
}
.about_card:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: pointer;
}

.about_icon{
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-block-end: 1rem
}
 .about_card h5{
     font-size: .95rem
 }

 .about_card small{
    font-size: .7rem;
    color: var(--color-light)
}

.about_content p{
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
    font-size: .7rem
}


/* =============== MEDIA QUERIES (MEDIAM DEVICES) ==================*/
@media screen and (max-width: 1024px)
{
    .about_container{
        grid-template-columns: 1fr;
        grid-gap: 0;
        margin: 0;
    }
    .about_me{
        width: 50%;
        margin: 1rem 0 1.5rem;
    }
    

    
}

/* =============== MEDIA QUERIES (SMALL DEVICES) ==================*/
@media screen and (max-width: 600px)
{
    .about_me{
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about_cards{
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
    }

    .about_content{
        text-align: center;
    }

    .about_content p{
        margin: 1.5rem 0;
    }
}