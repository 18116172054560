.portfolio_container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.3rem
}

.portfolio_item{
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio_item:hover{
    border-color:var(--color-primary-variant)
}

.portfolio_item-image{
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio_item h3{
    margin: 1.2rem 0 2rem;
}
.portfolio_item-cta {
    display: flex;
    grid-gap: 1rem;
    margin-bottom: 1rem;
}

/* =============== MEDIA QUERIES (MEDIAM DEVICES) ==================*/
@media screen and (max-width: 769px)
{
    .portfolio_container {
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.2rem
    }
    
}

/* =============== MEDIA QUERIES (SMALL DEVICES) ==================*/
@media screen and (max-width: 600px)
{
    .portfolio_container {
        grid-template-columns: 1fr;
        grid-gap: 1rem
        
    }
}
