nav{
    background: rgba(0, 0, 0, .3);
    width: max-content;
    padding: .7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    bottom: 2rem;
    display: flex;
    column-gap: .8rem;
    border-radius: 3rem;
    -webkit-backdrop-filter: blur(15px)
}
nav a{
    background: transparent;
    padding: .9rem;
    display: flex;
    border-radius: 3rem;
    color: var(--color-light);
    font-size: 1.1rem;
    
}

nav a:hover{
    background: rgba(0, 0, 0, .3);
       
}

nav a.active{
    background: var(--color-primary);
    color: var(--color-white);
}